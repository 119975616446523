import React from "react"
import * as styles from "../logged-out/logged-out.module.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LoggedOutImage from "../../components/img/loggedOutImage"

const LoggedOut = ({ children, location }) => (
  <Layout children={children} location={location}>
    <Seo title="Home" />
    <div className={styles.sectionContainer}>
      <div className={styles.sectionRight}>
        <LoggedOutImage />
      </div>
      <div className={styles.sectionLeft}>
        <h1>You have been logged out due to inactivity. Please log in again.</h1>
      </div>
    </div>
  </Layout>
)

export default LoggedOut
